const fontColors = {
  white: '#ffffff',
  black: '#000000',
  extraDark: '#333333',
  dark: '#444444',
  medium: '#555555',
  light: '#666666',
  extraLight: '#888888',
};

const label = {
  alternative: '#8A8A8A',
  alternativeDark: '#C1C2CD',
  disable: '#30303016',
  disableDark: '#e5e5e5',
  assistive: '#2A2A2A28',
  normal: '#171717',
  normalDark: '#fff',
  strong: 'rgba(0,0,0,1)',
  neutral: 'rgba(46, 47, 51, 0.88)',
  neutralDark: '#eeeeee',
};

const fill = {
  normal: '#70737C08',
  alternative: '#70737C05',
};

const colors = {
  badgeBackground: '#70737C14',
  transparent: '#ffffff00',
  whiteHalfTransparent: '#ffffffe0',
  white: '#ffffff',
  black: '#000000',
  primary100: '#417deb',
  primary200: '#3c72d7',
  primary300: '#3669c7',
  blue000: '#2c99ff15',
  blue100: '#edf5ff',
  blue200: '#2c99ff',
  blue300: '#0072f5',
  blue400: '#0065e5',
  lightblue: 'rgba(51, 160, 254, 1)',
  light000: '#fafafa',
  light100: '#f9f9f9',
  light200: '#f0f0f0',
  light300: '#e8e8e8',
  light400: '#d1d1d1',
  light500: '#a2a2a2',
  purple100: '#5f3dc408',
  purple200: '#5F3DC450',
  purple300: '#5F3DC4cc',
  indigo100: '#4c6ef508',
  indigo200: '#4c6ef550',
  indigo300: '#4c6ef5cc',
  yellow000: '#FFF9DB',
  yellow100: '#FFF3BF',
  yellow200: '#FFE066',
  yellow300: '#FFD43B',
  yellow400: '#FCC419',
  red100: '#e75e5f15',
  red200: '#fa541c',
  red300: '#e75e5f',
  green100: '#57b53f15',
  green300: '#57b53f',
  dark: '#202020',
  darkHover: 'rgba(249,249,250,0.03)',
};

const scheduleCategoryColors = {
  all: '#444444',
  all000: '#44444415',
  webinar: '#00b1f2',
  webinar000: '#00b1f215',
  conference: '#20c997',
  conference000: '#20c99715',
  competition: '#775dd0',
  competition000: '#775dd015',
  others: '#ff9800',
  others000: '#ff980015',
};

const techstackCategoryColors = {
  language100: '#5D403715',
  language200: '#5D4037',
  frontend100: '#512DA815',
  frontend200: '#512DA8',
  backend100: '#108ee915',
  backend200: '#108ee9',
  mobile100: '#13c2c215',
  mobile200: '#13c2c2',
  data100: '#00827515',
  data200: '#008275',
  devops100: '#24ad2915',
  devops200: '#24ad29',
  database100: '#722ed115',
  database200: '#722ed1',
  testing100: '#C2185B15',
  testing200: '#C2185B',
  others100: '#5a5f7d15',
  others200: '#5a5f7d',
};

const boxShadow = {
  cardShadow:
    'rgb(8 60 130 / 6%) 0px 0px 0px 0.05rem, rgb(30 34 40 / 4%) 0rem 0rem 1.25rem',
  cardHoverShadow: 'rgb(20 20 20 / 10%) 0px 8px 30px',
  popOverShadow: 'rgb(146 153 184 / 13%) 0px 5px 20px',
  lightShadow: 'rgb(146 153 184 / 8%) 0px 4px 16px',
  shadow: 'rgb(146 153 184 / 8%) 1px 2px 4px',
};

const layout = {
  mobile: '(max-width: 576px)',
  desktop: '(min-width: 1280px)',
};

const line = {
  normal: '#E1E2E4',
  neutral: '#EAEBEC',
  alternative: '#F4F4F5',
  shallow: '#00000005',
  border08: 'rgba(0,0,0,0.08)',
  border05: 'rgba(0,0,0,0.05)',
  dark: '#3a3a3a',
};

const lineDark = {
  light200: (dark) => (!dark ? '#f0f0f0' : '#3a3a3a'),
  light300: (dark) => (!dark ? '#e8e8e8' : '#3a3a3a'),
  alternative: (dark) => (!dark ? '#F4F4F5' : '#3a3a3a'),
  neutral: (dark) => (!dark ? '#EAEBEC' : '#3a3a3a'),
  normal: (dark) => (!dark ? '#E1E2E4' : '#3a3a3a'),
};

const interaction = {
  inactive: '#989BA2',
};

const accent = {
  lightBlue: '#0095FF',
  violet: 'rgba(122, 69, 229, 1)',
};

const size = {
  heading1: {
    size: 22,
    lh: '28px',
  },
  heading2: {
    size: 20,
    lh: '26px',
  },
  headline1: {
    size: 18,
    lh: '26px',
  },
  body1: {
    size: 16,
    lh: '24px',
  },
  body2: {
    size: 15,
    lh: '22px',
  },
  label1: {
    size: 14,
    lh: '20px',
  },
  label2: {
    size: 13,
    lh: '18px',
  },
  caption1: {
    size: 12,
    lh: '16px',
  },
  caption2: {
    size: 11,
    lh: '14px',
  },
};

const xsize = {
  heading1: (theme) => ({
    fontSize: 22,
    lineHeight: '28px',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 20,
      lineHeight: '26px',
    },
  }),
  heading2: (theme) => ({
    fontSize: 20,
    lineHeight: '26px',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 18,
      lineHeight: '26px',
    },
  }),
  headline1: (theme) => ({
    fontSize: 18,
    lineHeight: '26px',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  }),
  body1: (theme) => ({
    fontSize: 16,
    lineHeight: '24px',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 15,
      lineHeight: '22px',
    },
  }),
  body2: (theme) => ({
    fontSize: 15,
    lineHeight: '22px',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  }),
  label1: (theme) => ({
    fontSize: 14,
    lineHeight: '20px',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 13,
      lineHeight: '18px',
    },
  }),
  label2: (theme) => ({
    fontSize: 13,
    lineHeight: '18px',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
  }),
  caption1: (theme) => ({
    fontSize: 12,
    lineHeight: '16px',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 11,
      lineHeight: '14px',
    },
  }),
  caption2: () => ({
    fontSize: 11,
    lineHeight: '14px',
  }),
};

const darkMode = {
  alternative: (dark) => (dark ? '#C1C2CD' : '#8A8A8A'),
  disable: (dark) => (dark ? '#e5e5e5' : '#30303016'),
  assistive: (dark) => (dark ? '#7f7f7f' : '#2A2A2A28'),
  normal: (dark) => (dark ? '#fff' : '#171717'),
  strong: (dark) => (dark ? '#fff' : 'rgba(0,0,0,1)'),
  neutral: (dark) => (dark ? '#ffffff90' : 'rgba(46, 47, 51, 0.88)'),
  bgDark: (dark) => (dark ? '#202020' : '#fff'),
};

const themeVariables = {
  colors,
  fontColors,
  boxShadow,
  scheduleCategoryColors,
  techstackCategoryColors,
  layout,
  label,
  fill,
  line,
  interaction,
  accent,
  size,
  xsize,
  darkMode,
  lineDark,
};

export default themeVariables;
