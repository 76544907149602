const defaultTheme = {
  // Defines color scheme for all components, defaults to "light"
  colorScheme: 'light',

  // Controls focus ring styles:
  // auto – display focus ring only when user navigates with keyboard (default)
  // always – display focus ring when user navigates with keyboard and mouse
  // never – focus ring is always hidden (not recommended)
  focusRing: 'auto',

  // Change focus ring styles
  focusRingStyles: {
    //   styles(theme: MantineThemeBase): CSSObject,
    //   resetStyles(theme: MantineThemeBase): CSSObject,
    // inputStyles: (theme) => ({ borderWidth: `1.2px solid ${theme.colors.blue[3]}`}),
  },

  fontFamily:
    'Pretendard Variable, -apple-system, BlinkMacSystemFont, Apple SD Gothic Neo, sans-serif',

  lineHeight: 1.75,

  // Determines whether motion based animations should be disabled for
  // users who prefer to reduce motion in their OS settings
  respectReducedMotion: true,

  // Determines whether elements that do not have pointer cursor by default
  // (checkboxes, radio, native select) should have it
  cursorType: 'pointer',

  // Default border-radius used for most elements
  defaultRadius: '0.75rem',

  // Object of arrays with 10 colors
  // colors: Record<string, Tuple<string, 10>>;

  // Key of theme.colors
  // primaryColor: theme.colors.blue,

  // Index of color from theme.colors that is considered primary, Shade type is 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  // primaryShade: Shade | { light: Shade; dark: Shade };

  // Default gradient used in components that support `variant="gradient"` (Button, ThemeIcon, etc.)
  // defaultGradient: { deg: number; from: MantineColor; to: MantineColor };

  // font-family and line-height used in most components
  // lineHeight: string | number;

  // Timing function used for animations, defaults to 'ease'
  transitionTimingFunction: 'ease',

  // Monospace font-family, used in Code, Kbd and Prism components
  // fontFamilyMonospace: string;

  // Sizes for corresponding properties
  // fontSizes: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;
  // radius: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;
  // spacing: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;

  // Values used for box-shadow
  shadows:
    'rgb(8 60 130 / 6%) 0px 0px 0px 0.05rem, rgb(30 34 40 / 4%) 0rem 0rem 1.25rem',

  // Breakpoints used in some components to add responsive styles
  // breakpoints: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;

  // Styles added to buttons with `:active` pseudo-class
  // activeStyles: CSSObject;

  // h1-h6 styles, used in Title and TypographyStylesProvider components
  // headings: {
  //   fontFamily: CSSProperties['fontFamily'];
  //   fontWeight: CSSProperties['fontWeight'];
  //   sizes: {
  //     // See heading options below
  //     h1: Heading;
  //     h2: Heading;
  //     h3: Heading;
  //     h4: Heading;
  //     h5: Heading;
  //     h6: Heading;
  //   };
  // };

  // theme functions, see in theme functions guide
  // fn: MantineThemeFunctions;

  // Left to right or right to left direction, see RTL Support guide to learn more
  // dir: 'ltr' | 'rtl';

  // Default loader used in Loader and LoadingOverlay components
  loader: 'dots',

  // Default date format used in DatePicker and DateRangePicker components
  dateFormat: 'YYYY-MM-DD',

  // Default dates formatting locale used in every @mantine/dates component
  datesLocale: 'ko',

  // defaultProps, styles and classNames for components
  // components: ComponentsOverride;

  // Global styles
  globalStyles: () => ({
    '.mantine-TextInput-input': {
      height: '38px !important',
    },
    '.mantine-Select-input': {
      height: '38px !important',
    },
    '.mantine-Autocomplete-input': {
      height: '38px !important',
    },
    '.mantine-InputWrapper-error': {
      fontSize: '13px !important',
      fontWeight: '500 !important',
      marginTop: '8px !important',
    },
    '.mantine-Input-input[data-invalid="true"]': {
      backgroundColor: '#fef2ed !important',
      border: 'none !important',
    },
  }),

  // Add your own custom properties on Mantine theme
  // other: Record<string, any>;
};

export default defaultTheme;
