import { Button as MantineButton, Sx, createStyles } from '@mantine/core';
import { MouseEventHandler, ReactNode } from 'react';

import { isDark } from '@/utils/utils';
import themeVariables from '@/styles/themeVariables';

export const ButtonTypes = {
  solidColored: 'solid-colored',
  brightColored: 'bright-colored',
  whiteRoundedRaised: 'white-rounded-raised',
  whiteRaised: 'white-raised',
  bordered: 'bordered',
  transparentGray: 'transparent-gray',
  gray: 'gray',
  disabled: 'disabled',
};

const useButtonStyles = createStyles((theme) => ({
  solidColored: {
    height: 42,
    fontWeight: 500,
    padding: '0px 16px',
    background: '#33A0FE',
  },

  brightColored: {
    height: 42,
    fontWeight: 500,
    padding: '0px 16px',
    backgroundColor: theme.colors.blue[0],
    color: theme.colors.blue[7],
    '&:hover': {
      backgroundColor: theme.colors.blue[1],
    },
  },

  whiteRoundedRaised: {
    height: 50,
    fontWeight: 500,
    padding: '0px 16px',
    backgroundColor: theme.white,
    color: themeVariables.fontColors.light,
    borderRadius: '40px',
    boxShadow: 'rgb(158 158 158 / 30%) 0px 14px 16px 0px',
    '&:hover': {
      backgroundColor: themeVariables.colors.light000,
    },
  },

  whiteRaised: {
    height: 42,
    fontWeight: 500,
    padding: '0px 18px',
    backgroundColor: themeVariables.colors.white,
    color: themeVariables.fontColors.light,
    boxShadow: themeVariables.boxShadow.lightShadow,
    '&:hover': {
      backgroundColor: themeVariables.colors.white,
      boxShadow: themeVariables.boxShadow.popOverShadow,
    },
  },

  gray: {
    height: 42,
    fontWeight: 500,
    padding: '0px 16px',
    backgroundColor: theme.colors.gray[1],
    color: theme.colors.gray[7],
    '&:hover': {
      backgroundColor: theme.colors.gray[2],
    },
  },

  disabled: {
    height: 42,
    fontWeight: 500,
    padding: '0px 16px',
    backgroundColor: theme.colors.gray[2],
    color: theme.colors.gray[5],
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: theme.colors.gray[2],
      color: theme.colors.gray[5],
    },
  },

  transparentGray: {
    height: 42,
    fontWeight: 500,
    padding: '0px 16px',
    backgroundColor: '#ffffff00',
    color: themeVariables.fontColors.medium,
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },

  bordered: {
    height: 42,
    fontWeight: 600,
    padding: '0px 16px',
    backgroundColor: themeVariables.colors.whiteHalfTransparent,
    color: themeVariables.fontColors.light,
    border: `1px solid ${themeVariables.line.neutral}`,
    borderRadius: 12,
    '&:hover': {
      border: `1px solid ${themeVariables.line.normal}`,
      backgroundColor: themeVariables.colors.whiteHalfTransparent,
    },
  },
}));

type Props = {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  type?: string;
  loading?: boolean;
  fullWidth?: boolean;
  children?: ReactNode | ReactNode[];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: Sx;
  [key: string]: any;
};

const Button = ({
  leftIcon,
  rightIcon,
  type,
  loading,
  fullWidth = false,
  children,
  onClick,
  style,
  ...props
}: Props) => {
  const { dark } = isDark();
  const { classes } = useButtonStyles();

  let theme = classes.solidColored;

  if (type == 'solid-colored') theme = classes.solidColored;
  if (type == 'bright-colored') theme = classes.brightColored;
  if (type == 'white-rounded-raised') theme = classes.whiteRoundedRaised;
  if (type == 'white-raised') theme = classes.whiteRaised;
  if (type == 'gray') theme = classes.gray;
  if (type == 'transparent-gray') theme = classes.transparentGray;
  if (type == 'bordered') theme = classes.bordered;
  if (type == 'disabled') theme = classes.disabled;

  return (
    <MantineButton
      className={theme}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      loading={loading}
      fullWidth={fullWidth}
      onClick={onClick}
      sx={{
        '&:hover': {
          backgroundColor: dark ? '#1c1c1c' : themeVariables.colors.light100,
          color: dark
            ? themeVariables.colors.white
            : themeVariables.label.normal,
        },
        ...style,
      }}
      styles={() => ({
        leftIcon: {
          marginRight: 6,
        },
      })}
      {...props}
    >
      {children}
    </MantineButton>
  );
};

export default Button;
