export const HEADER_MENU_LIST = [
  { label: '디스커버리', link: `/discoveries` },
  {
    label: '기술 스택',
    dropdownMenu: [
      { label: '기업별로 보기', link: '/company/list' },
      { label: '기술별로 보기', link: '/techstack/list' },
      { label: '기술 인기도', link: '/statistics/list' },
    ],
    link: '/company/list',
  },
  { label: '컨퍼런스 일정', link: `/calendar/month` },
  { label: '기술 아키텍처', link: `/architecture/list` },
  { label: '기술 블로그', link: `/techblog/list` },
  { label: '채용공고', link: `/jobpost` },
  { label: '기술평가', link: `/evaluation` },
];
