import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import _isPlainObject from 'lodash/isPlainObject';
import _isString from 'lodash/isString';

export const useScrollInit = (
  id = 'header-top',
  move: ScrollBehavior = 'auto'
) => {
  const elem = document?.getElementById(id);
  elem?.scrollIntoView({
    behavior: move,
    block: 'nearest',
  });
};

export const useScrollBehavior = (
  top: number,
  move: ScrollBehavior = 'instant' as ScrollBehavior
) => {
  setTimeout(() => {
    window.scrollTo({
      top,
      behavior: move,
    });
  }, 0);
};

export const diffDateFromNow = (date: Date) => {
  const oldDate = new Date(date).setHours(0, 0, 0, 0);
  const newDate = new Date().setHours(0, 0, 0, 0);

  let diff = newDate - oldDate;
  diff = diff / (1000 * 60 * 60 * 24);

  return diff;
};

export const removeScrollStorage = () => {
  localStorage.removeItem('architecture_scroll');
  localStorage.removeItem('company_scroll');
  localStorage.removeItem('techstack_scroll');
};

/**
 * GA 트래킹 도구
 */
export const sendGTMEvent = (valueOrObject, key = 'event') => {
  if (typeof window === 'undefined') {
    return;
  }

  if (!_isFunction(_get(window, 'dataLayer.push'))) {
    return;
  }

  try {
    if (_isString(valueOrObject)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.dataLayer.push({
        [key]: valueOrObject,
      });
    } else if (_isPlainObject(valueOrObject)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.dataLayer.push(valueOrObject);
    }
  } catch (e) {
    // exception
  }
};
