export const images = {
  logoOnly: '/assets/logo-only.png',
  discoveryGif: '/assets/discovery.gif',
  architectureIcon: '/assets/MenuIcons/architecture.png',
  calendarIcon: '/assets/MenuIcons/calendar.png',
  discoveryIcon: '/assets/MenuIcons/discovery.png',
  discussionIcon: '/assets/MenuIcons/discussion.png',
  techblogIcon: '/assets/MenuIcons/techblog.png',
  techstackIcon: '/assets/MenuIcons/techstack.png',
  jobpostIcon: '/assets/MenuIcons/jobpost.png',
  headerLogo: '/assets/logo-dark.png',
  quotationMark: '/assets/quotation-mark.png',
  googleLogo: '/assets/SNSLogos/google.png',
  kakaoLogo: '/assets/SNSLogos/kakao.png',
  naverLogo: '/assets/SNSLogos/naver.png',
  mediumLogo: '/assets/SNSLogos/medium.png',
  velogLogo: '/assets/SNSLogos/velog.png',
  tistoryLogo: '/assets/SNSLogos/tistory.png',
  facebookLogo: '/assets/SNSLogos/facebook.svg',
  githubLogo: '/assets/SNSLogos/github.svg',
  instagramLogo: '/assets/SNSLogos/instagram.svg',
  linkedinLogo: '/assets/SNSLogos/linkedin.svg',
  twitterLogo: '/assets/SNSLogos/twitter.svg',
  youtubeLogo: '/assets/SNSLogos/youtube.svg',
  discordLogo: '/assets/SNSLogos/discord.png',
  processingGif: '/assets/github-processing.gif',
  notFoundGif: '/assets/not-found-404.gif',
  noCommentGif: '/assets/no-comment.gif',
  completeGif: '/assets/complete.gif',
  codingGif: '/assets/coding-animation.gif',
  comingSoonGif: '/assets/coming-soon.gif',
  emptyGif: '/assets/empty-result.gif',
  preOnboarding: '/assets/pre-onboarding.jpg',
  slackLogo: '/assets/SNSLogos/slack.png',
  defaultProfile: '/assets/default-profile.png',
  circleQuestion: '/assets/circle-question.png',
  ghost: '/assets/ghost.png',
  widget: '/assets/widget.mp4',
  codenary: '/assets/codenary.png',
  pointing: '/assets/pointing.png',
};

export const WHITE_URL =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
export const BLUR_URL =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFklEQVR42mN8//HLfwYiAOOoQvoqBABbWyZJf74GZgAAAABJRU5ErkJggg==';

export const imageOpt = (src, w, q = 90) => {
  return `https://image.wanted.co.kr/optimize?src=${src}&w=${w}&q=${q}`;
};
