import { FilterProps } from '@/features/Jobpost/hook/api';
import { IUser } from '@/types/common';
import { atom } from 'jotai';

export const isSignedIn = atom(false);

export const initUser = {
  username: '',
  login_type: '',
  email: '',
  img_url: '',
  company: '',
  job: '',
  experience: 0,
  is_employed: false,
  login_type_id: '',
};

export const notifications = atom({
  totalCount: 0,
  redDot: false,
  data: [],
});

export const userInfo = atom<IUser>({} as IUser);

export const wantedJobpostFilter = atom<FilterProps>({
  career: '',
  locations: [],
  sizes: [],
  leaveRates: [],
  salaries: [],
});

export const userProfileIsActive = atom(false);

export const userProfile = atom({
  basicInfo: { visible: true, data: {} as any },
  introductionInfo: { visible: true, data: '' },
  snsInfo: { visible: true, data: {} as any },
  educationInfo: { visible: false, data: [] as any },
  careerInfo: { visible: false, data: [] as any },
  projectInfo: { visible: false, data: [] as any },
  techstackInfo: { visible: true, data: [] as any },
  speechInfo: { visible: true, data: [] as any },
  contentInfo: { visible: true, data: [] as any },
  youtubeInfo: { visible: true, data: {} as any },
});
