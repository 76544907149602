import mixpanel from 'mixpanel-browser';

export const mixpanelTrack = (obj, action, properties = {}) => {
  if (process.env.NEXT_PUBLIC_STAGE === 'production') {
    mixpanel.track(`${obj} ${action}`, properties);
  } else {
    console.log(`LOG: ${obj} ${action}`, properties);
  }
};

export const mixpanelIdentifyEmail = (userInfo) => {
  mixpanel.identify(userInfo?.email);
  mixpanel.people.set({
    $name: userInfo?.username,
    $email: userInfo?.email,
    $company: userInfo?.company,
    $experience: userInfo?.experience,
    $job: userInfo?.job,
  });
};
