import {
  ActionIcon,
  Box,
  Container,
  Group,
  Text,
  createStyles,
} from '@mantine/core';
import { IconBrandInstagram, IconMail } from '@tabler/icons-react';
import { getYear, isDark } from '@/utils/utils';

import DevEndpointModal from '@/features/Main/components/DevEndpointModal';
import themeVariables from '@/styles/themeVariables';

function Footer() {
  const { dark } = isDark();
  const { classes } = useFooterStyle();

  return (
    <Box
      sx={{
        backgroundColor: dark ? themeVariables.colors.dark : '#fff',
        borderTop: `1px solid ${themeVariables.lineDark.light300(dark)}`,
      }}
    >
      <Container className={classes.inner}>
        <Text className={classes.text} fz={'sm'}>
          Copyright © {getYear()}. Codenary All Rights Reserved.
        </Text>
        <Group
          spacing={0}
          className={classes.linkGroup}
          position="right"
          noWrap
        >
          <DevEndpointModal />
          <Text
            className={classes.link}
            component={'a'}
            fz={'sm'}
            href="https://www.wanted.co.kr/terms"
            underline
            target={'_blank'}
          >
            서비스 이용약관
          </Text>
          <Text
            className={classes.link}
            component={'a'}
            fz={'sm'}
            href="https://www.wanted.co.kr/privacy"
            underline
            target={'_blank'}
          >
            개인정보 처리방침
          </Text>
          <ActionIcon
            size={'lg'}
            onClick={() =>
              window.open(
                'https://www.instagram.com/codenary_official',
                '_blank'
              )
            }
            aria-label="instagram"
          >
            <IconBrandInstagram size={21} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            size={'lg'}
            onClick={() =>
              window.open('mailto:codenary@wantedlab.com', '_blank')
            }
            aria-label="email"
          >
            <IconMail size={21} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </Box>
  );
}

const useFooterStyle = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 16,
    maxWidth: 1280,
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  linkGroup: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: 8,
    },
  },

  link: {
    margin: '0px 4px',
    fontWeight: 600,
    color: theme.colors.dark[2],
    ':hover': {
      color: theme.colors.blue[7],
    },
  },

  text: {
    color: theme.colors.dark[2],
  },
}));

export default Footer;
