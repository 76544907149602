const getLocalStorageItem = (key: string) => {
  const data = typeof window !== 'undefined' ? localStorage.getItem(key) : ''

  try {
    return JSON.parse(data as any)
  } catch (err) {
    return data
  }
}

const setLocalStorageItem = (key: string, value: any) => {
  const stringify = typeof value !== 'string' ? JSON.stringify(value) : value
  return localStorage.setItem(key, stringify)
}

const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key)
}


const LOCALSTORAGE_ITEMS = {
  userInfo: 'userinfo',
  accessToken: 'access_token'
}


export { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem, LOCALSTORAGE_ITEMS};
