import Button, { ButtonTypes } from '@/components/Buttons/Button';
import {
  COOKIE_ITEMS,
  getCookieItem,
  removeCookieItem,
  setCookieItem,
} from '@/utils/cookieControl';
import { Group, Modal, Text, TextInput } from '@mantine/core';

import { ENDPOINT } from '@/config/endpoint';
import themeVariables from '@/styles/themeVariables';
import { useState } from 'react';

const DevEndpointModal = () => {
  const [modalVisibile, setModalVisibile] = useState(false);
  const [endpointInput, setEndpointInput] = useState(
    getCookieItem(COOKIE_ITEMS.endpoint) || ENDPOINT
  );

  const handleOk = () => {
    setCookieItem(COOKIE_ITEMS.endpoint, endpointInput, 3600);
    setModalVisibile(false);
  };

  const handleReset = () => {
    removeCookieItem(COOKIE_ITEMS.endpoint);
    setModalVisibile(false);
  };

  const handleClose = () => {
    setModalVisibile(false);
  };

  return (
    process.env.NEXT_PUBLIC_STAGE != 'production' && (
      <>
        <Text
          mx={4}
          fz={'sm'}
          component={'span'}
          underline={false}
          color={
            getCookieItem(COOKIE_ITEMS.endpoint)
              ? themeVariables.colors.blue300
              : themeVariables.fontColors.extraLight
          }
          className={'pointer-cursor'}
          onClick={() => {
            setModalVisibile(true);
          }}
        >
          {getCookieItem(COOKIE_ITEMS.endpoint) ? '설정 적용 중' : '설정'}
        </Text>
        <Modal
          centered
          withinPortal
          opened={modalVisibile}
          withCloseButton={false}
          padding={24}
          onClose={handleClose}
          sx={{ zIndex: 2000 }}
          title={
            <Text weight={700} color={themeVariables.fontColors.medium}>
              최근 추가된 스타트업
            </Text>
          }
        >
          <TextInput
            data-autofocus
            my={20}
            defaultValue={endpointInput}
            variant={'filled'}
            placeholder={'SERVER_ENDPOINT'}
            onChange={(e) => setEndpointInput(e.target.value)}
            onKeyDownCapture={(e) => e.key == 'Enter' && handleOk()}
          />
          <Text size={14} color={themeVariables.fontColors.extraLight}>
            * 기본 만료 기간: 1시간
          </Text>
          <Group spacing={4} mt={30} position={'right'}>
            <Button type={ButtonTypes.gray} onClick={handleReset}>
              초기화
            </Button>
            <Button type={ButtonTypes.solidColored} onClick={handleOk}>
              설정
            </Button>
          </Group>
        </Modal>
      </>
    )
  );
};

export default DevEndpointModal;
