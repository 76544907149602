import { API_ENDPOINT } from '@/config/endpoint';
import { useQuery } from '@tanstack/react-query';

export const useGetTechblogList = ({ page, keyword, tag, company }) => {
  const keyArr = [page, keyword, tag, company].filter((item) => !!item);
  return useQuery(
    ['techblog', 'list', ...keyArr],
    API_ENDPOINT.techblog.getArticleList({
      page,
      keyword,
      tag,
      companyId: company,
    }),
    {
      staleTime: 1000 * 60 * 10,
    }
  );
};

export const useGetFavoriteTechblog = () => {
  return useQuery(
    ['techblog', 'favorite', 'ids'],
    API_ENDPOINT.techblog.getFavoriteIDList
  );
};

export const useGetTechblogInfo = ({ keyword, tag }) => {
  const key = keyword || tag;
  return useQuery(
    ['techblog', 'basicinfo', key],
    API_ENDPOINT.techblog.getBasicInfo({ keyword, tag }),
    {
      staleTime: 1000 * 60 * 10,
    }
  );
};

export const useGetTechblogCategoryList = () => {
  return useQuery(
    ['techblog', 'category', 'list'],
    API_ENDPOINT.techblog.getCategoryList,
    {
      staleTime: 1000 * 60 * 10,
    }
  );
};

export const useGetTechblogRecommendList = () => {
  return useQuery(
    ['techblog', 'recommended', 'list'],
    API_ENDPOINT.techblog.getRecommendedTagList,
    {
      staleTime: 1000 * 60 * 10,
    }
  );
};

export const useGetTechblogRanking = () => {
  return useQuery(
    ['techblog', 'popular_article'],
    API_ENDPOINT.techblog.getPopularArticle,
    {
      staleTime: 1000 * 60 * 10,
    }
  );
};
