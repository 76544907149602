'use client';

import './globals.css';
import 'dayjs/locale/ko';

import * as atoms from '@/config/globalStateControl';

import { COOKIE_ITEMS, getCookieItem } from '@/utils/cookieControl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, Suspense, useEffect } from 'react';
import { init, setUserId } from '@amplitude/analytics-browser';

import AOS from 'aos';
import { API_ENDPOINT } from '@/config/endpoint';
import Footer from '@/layout/Footer';
import Header from '@/layout/Header';
import { IUser } from '@/types/common';
import { ModalsProvider } from '@mantine/modals';
import RootStyleRegistry from './emotion';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';
import { isDark } from '@/utils/utils';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import mixpanel from 'mixpanel-browser';
import styles from './body.module.css';
import themeVariables from '@/styles/themeVariables';
import timezone from 'dayjs/plugin/timezone';
import { useSetAtom } from 'jotai';

const Notifications = dynamic(
  () => import('@mantine/notifications').then((noti) => noti.Notifications),
  { ssr: false }
);

const Toaster = dynamic(
  () => import('react-hot-toast').then((toaster) => toaster.Toaster),
  { ssr: false }
);

const UserRegisterModal = dynamic(
  () => import('@/features/Account/UserRegisterModal'),
  { ssr: false }
);

mixpanel.init('cb027f6c069d4ead16dd6bc3a196a638', {
  debug: true,
  ignore_dnt: true,
});

dayjs.extend(isLeapYear); // 플러그인 등록
dayjs.extend(timezone);
dayjs.locale('ko'); // 언어 등록
dayjs.tz.setDefault('Asia/Seoul');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

async function fetchData() {
  const isSignIn = getCookieItem(COOKIE_ITEMS.isSignIn);

  if (isSignIn === 'true') {
    const userData = await API_ENDPOINT.auth.getUser();
    return userData;
  }

  return {};
}

export default function RootClientLayout({
  children,
}: {
  children: ReactNode;
}) {
  const setSignedIn = useSetAtom(atoms.isSignedIn);
  const setUserinfo = useSetAtom(atoms.userInfo);

  const data = fetchData();

  useEffect(() => {
    data
      .then((user: IUser) => {
        if (user.login_type !== undefined) {
          setSignedIn(true);
          setUserinfo(user);
          init(process.env.NEXT_PUBLIC_AMPLITUDE || '', {
            defaultTracking: {
              attribution: true,
              pageViews: false,
              sessions: false,
              formInteractions: false,
              fileDownloads: false,
            },
          }).promise.then(() => {
            setUserId(user.login_type_id);
          });
        } else {
          setSignedIn(false);
          setUserinfo(atoms.initUser);
        }
      })
      .catch(() => {
        setSignedIn(false);
        setUserinfo(atoms.initUser);
      });

    AOS.init({ once: true });
  }, []);

  return (
    <body>
      <div id="header-top" />
      <RootStyleRegistry>
        <QueryClientProvider client={queryClient}>
          <Header />
          <ModalsProvider>
            <Notifications
              position={'top-right'}
              zIndex={2002}
              limit={1}
              containerWidth={360}
            />
            <Toaster
              toastOptions={{ duration: 2000 }}
              containerStyle={{ fontSize: 14 }}
            />
            <Body body={children} />
            <Footer />
          </ModalsProvider>
        </QueryClientProvider>
      </RootStyleRegistry>
    </body>
  );
}

const Body = ({ body }: any) => {
  const { dark } = isDark();

  return (
    <main
      style={{
        backgroundColor: themeVariables.darkMode.bgDark(dark),
        transition: 'all 0.3s ease',
      }}
    >
      <div className={styles.body}>
        {body}
        <Suspense>
          <UserRegisterModal />
        </Suspense>
      </div>
    </main>
  );
};
