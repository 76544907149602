import {
  Avatar,
  Flex,
  Group,
  Loader,
  Popover,
  Text,
  createStyles,
} from '@mantine/core';
import Button, { ButtonTypes } from '@/components/Buttons/Button';
import {
  IconActivity,
  IconId,
  IconLogout,
  IconUser,
} from '@tabler/icons-react';
import { initUser, isSignedIn, userInfo } from '@/config/globalStateControl';
import { useAtom, useSetAtom } from 'jotai';
import { useClickOutside, useDisclosure } from '@mantine/hooks';

import { API_ENDPOINT } from '@/config/endpoint';
import { BLUR_URL } from '@/utils/images';
import DMText from '@/components/DMText';
import Link from 'next/link';
import { isDark } from '@/utils/utils';
import { mixpanelTrack } from '@/utils/mixpanel';
import { startTransition } from 'react';
import themeVariables from '@/styles/themeVariables';

const useUserProfileButtonStyle = createStyles((theme, dark: any) => ({
  userProfileContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    cursor: 'pointer',
    color: themeVariables.fontColors.medium,
  },

  userProfileIcon: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  popoverContainer: {
    padding: 4,
  },

  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
  },

  popoverContentProfileContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.5rem',
    padding: 20,
  },

  popoverContentProfileDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },

  popoverContentMenuItemContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    padding: '12px 18px',
    margin: 4,
    fontSize: 14,
    color: themeVariables.darkMode.normal(dark?.dark),
    cursor: 'pointer',
    borderRadius: '0.5rem',
    '&:hover': {
      backgroundColor: dark?.dark
        ? themeVariables.colors.light500
        : themeVariables.colors.light000,
    },
    '&:active': {
      backgroundColor: themeVariables.colors.light100,
    },
  },
}));

const LoginButton = () => {
  const loginOneID = () => {
    window.location.href = `${
      process.env.NEXT_PUBLIC_WANTED_ONEID
    }/login?client_id=${process.env.NEXT_PUBLIC_WANTED_CLIENTID}&before_url=${
      process.env.NEXT_PUBLIC_BASE_URL +
      window.location.pathname +
      window.location.search
    }&redirect_url=${
      process.env.NEXT_PUBLIC_AUTH_ENDPOINT
    }/login/wanted&message_key=codenary_default`;

    startTransition(() => mixpanelTrack('LoginPage', 'Viewed'));
  };

  return (
    <Button
      type={ButtonTypes.gray}
      style={{
        height: 36,
        width: 140,
        '&:hover': {
          backgroundColor: themeVariables.colors.light000,
        },
      }}
      onClick={loginOneID}
    >
      <Flex gap={8}>
        <Text>로그인</Text>
        <Text>|</Text>
        <Text>회원가입</Text>
      </Flex>
    </Button>
  );
};

const UserProfilePopup = () => {
  const { dark } = isDark();
  const { classes } = useUserProfileButtonStyle({ dark });

  const [popoverOpened, popoverHandler] = useDisclosure(false);
  const popoverRef = useClickOutside(() => popoverHandler.close(), ['mouseup']);

  const setSignedIn = useSetAtom(isSignedIn);
  const [user, setUser] = useAtom(userInfo);

  const handleLogoutClick = async () => {
    await API_ENDPOINT.auth.logoutUser().then(() => {
      setSignedIn(false);
      setUser(initUser);
    });

    window.location.href = `https://id.wanted.jobs/logout?before_url=${
      process.env.NEXT_PUBLIC_BASE_URL +
        (window.location.pathname + window.location.search) || '/'
    }&client_id=${process.env.NEXT_PUBLIC_WANTED_CLIENTID}`;
  };

  if (user.login_type === undefined)
    return (
      <Group
        position={'center'}
        sx={(theme) => ({
          width: 140,
          height: 36,
          backgroundColor: theme.colors.gray[0],
          borderRadius: 10,
        })}
      >
        <Loader />
      </Group>
    );

  if (!user?.login_type) return <LoginButton />;

  return (
    <Popover
      width={240}
      position={'bottom'}
      withArrow
      shadow={themeVariables.boxShadow.popOverShadow}
      opened={popoverOpened}
      withinPortal
      zIndex={999}
    >
      <Popover.Target>
        <div
          className={classes.userProfileContainer}
          onClick={() => popoverHandler.toggle()}
          role="button"
        >
          <Avatar
            src={user.img_url || BLUR_URL}
            ml={8}
            radius={20}
            alt="user-image"
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown
        className={classes.popoverContainer}
        onClick={() => popoverHandler.close()}
      >
        <div ref={popoverRef} className={classes.popoverContent}>
          <div
            className={classes.popoverContentProfileContainer}
            style={{
              backgroundColor: dark
                ? 'transparent'
                : themeVariables.colors.light000,
            }}
          >
            <Avatar
              mr={16}
              src={user.img_url}
              radius={'0.5rem'}
              size={'lg'}
              sx={{ backgroundColor: '#fff' }}
            />
            <div className={classes.popoverContentProfileDescriptionContainer}>
              <DMText
                weight={700}
                size={15}
                dColor={themeVariables.darkMode.strong}
              >
                {user.username}
              </DMText>
              <DMText
                weight={400}
                size={13}
                dColor={themeVariables.darkMode.neutral}
              >
                {user.company} / {user.job}
              </DMText>
            </div>
          </div>
          <Link href={`/user-profile/me`}>
            <div className={classes.popoverContentMenuItemContainer}>
              <IconId size={18} color={themeVariables.darkMode.neutral(dark)} />
              <Text>내 개발 프로필</Text>
            </div>
          </Link>
          <Link href={`/my-activity`}>
            <div className={classes.popoverContentMenuItemContainer}>
              <IconActivity
                size={18}
                color={themeVariables.darkMode.neutral(dark)}
              />
              <Text>나의 활동</Text>
            </div>
          </Link>
          <Link href={`/edit-user`}>
            <div className={classes.popoverContentMenuItemContainer}>
              <IconUser
                size={18}
                color={themeVariables.darkMode.neutral(dark)}
              />
              <Text>계정 설정</Text>
            </div>
          </Link>
          <Button
            leftIcon={<IconLogout size={18} />}
            type={ButtonTypes.gray}
            onClick={handleLogoutClick}
          >
            로그아웃
          </Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default UserProfilePopup;
