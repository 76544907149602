import { Text, TextProps } from '@mantine/core';

import { isDark } from '@/utils/utils';

interface Props extends TextProps {
  dColor: any;
  id?: string;
  onClick?: any;
}

const DMText = (props: Props) => {
  const { dark } = isDark();
  const { children, dColor, ...etc } = props;

  return (
    <Text {...etc} color={typeof dColor === 'function' ? dColor(dark) : dColor}>
      {children}
    </Text>
  );
};

export default DMText;
